/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/templates/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

 #wrapper {
    /* overflow-x: hidden; */
 }

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  background-color: #1F1F1F;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  z-index: 999;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  width: calc(100% + 200px);
  margin-left: 200px;
  transition: 0.5s;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
@media only screen and (max-width: 1024px) {
  #sidebar-wrapper {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
  }
}
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

/*  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }*/
}

.container-with-scroll {
  width: calc(100vw - 315px);
}

.container-with-scroll #mainNavBar {
  width: calc(100vw - 205px);
  position: fixed;
  z-index: 9999;
}


.dropdown-menu-right {
  top:  -100% !important;
  left: 104%!important;
  position: absolute;
  transform: translate3d(5px, 50px, 0px);
  will-change: transform;
  padding-left: 5px;
  padding-right: 5px;
}

.dropdown-menu-bottom {
  margin-top:  0;
  margin-left: 0;
  float: none!important;
  position: relative!important;
  transform:none!important;
  padding-left: 5px;
  padding-right: 5px;
}

#sidebar-wrapper {
  padding-top: 15px;
  transition: 0.5s;
  padding-left: 5px;
  padding-right: 5px;
  position: fixed;
}

#wrapper .short-menu{
  display: none;
}

#wrapper.toggled #sidebar-wrapper{
  width: 83px;
  max-width: 83px;
  min-width: 83px;
}
#wrapper.toggled #sidebar-wrapper .short-menu {
  margin-right: 4px;
}

#wrapper.toggled #sidebar-wrapper .dropdown-menu-right,
#wrapper.toggled #sidebar-wrapper .dropdown-menu-bottom {
  left: 117%!important;
}

#wrapper.toggled .full-menu{
  display: none;
}
#wrapper.toggled .container-with-scroll {
  width: calc(100vw - 83px);
  margin-left: 83px;
  transition: 0.5s;
}

#wrapper.toggled .container-with-scroll #mainNavBar {
  width: calc(100vw - 83px);
}

#wrapper.toggled .short-menu{
  display: block;
}


.dropdown-menu-bottom a{
  border-left:  3px solid #d9d9d9;
  margin-left: 20px;
  color: #d9d9d9;
}

.dropdown-menu-bottom .dropdown-menu-right .dropdown-item:hover,
.dropdown-menu-bottom .dropdown-menu-bottom .dropdown-item:hover {
  background-color: transparent!important;
  color: #ffffff;
  border-color: #ffffff;
}
