@import '~@ng-select/ng-select/themes/default.theme.css';
//@import '~swiper/swiper-bundle.min.css';
//@import "assets/css/stylesheet.css";
/* You can add global styles to this file, and also import other style files */

:root {
  --main-menu-width: 60px;
  --main-menu-open-width: 220px;
  --primary-font-face: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --accent-color: #00b2a8;
  --dark-blue: #131e34;
  --light-blue: #00cabf;
  --dark-gray: rgba(75, 75, 75, 0.5);
  --light-gray: rgba(208, 208, 208, 0.5);
  --text-color-secondary: #5d6262;
  --text-color-primary: #27333b;
  --dark-gray-secondary: #4b4b4b;
  --mobile-menu-height: 60px;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: var(--primary-font-face);
  font-weight: normal;
  font-style: normal;
  background-color: #282828 !important;
  &.modal-open {
    overflow-y: hidden;
  }
}

.is-mobile-modal {
  padding: 0px 10px;
}
::ng-deep div.modal-header {
  button.close {
    appearance: none;
    background: none;
    border: none;
  }
}

input {
  font-size: 14px !important;
}

button {
  font-size: 14px;
}

button.p-button {
  border-color: inherit;
  span.p-button-label {
    font-size: 14px;
  }
}

// TODO: временно, пока не заменим на новый компонент `form-field`
div.form-field {
  label {
    font-family: var(--primary-font-face);
    font-weight: 500;
    height: 1.1875rem;
    font-size: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #646464;
  }
  input {
    font-size: 14px;
  }

  ::ng-deep {
    .p-selectbutton {
      .p-button {
        .p-button-label {
          font-size: 14px !important;
        }
      }
    }

    ng-select .ng-select-container .ng-value-container .ng-input > input {
      font-size: 14px !important;
    }
  }
}

.ng-select.control {
  font-size: 14px;
}

table {
  margin: 0;
  width: 100%;
  font-size: 14px;
}

.notification-body {
  position: absolute !important;
  top: 20px;
  right: 60px;
  z-index: 1000;
}

div.card {
  margin-bottom: 0.5rem;
  div.card-header {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    cursor: pointer;
  }
}

.btn-circle.btn-xl {
  width: 60px !important;
  height: 60px !important;
  border-radius: 35px !important;
  font-size: 19px !important;
  line-height: 1.33 !important;
  opacity: 0.5;
}

.btn-circle.btn-xl:hover {
  opacity: 1;
}

.btn-circle {
  width: 30px !important;
  height: 30px !important;
  padding: 6px 0 !important;
  border-radius: 15px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 1.42857 !important;
}

.required {
  .ng-select {
    .ng-placeholder {
      &:after {
        content: ' *';
        color: red;
      }
    }
  }
}

.form-control {
  height: calc(1.5em + 0.75rem + 0px) !important;
}

/**
/** The Magic **/

/** The Spacing **/
.btn-breadcrumb .btn {
  padding: 6px 12px 6px 24px !important;
}

.btn-breadcrumb .btn:first-child {
  padding: 6px 6px 6px 10px !important;
}

.btn-breadcrumb .btn:last-child {
  padding: 6px 18px 6px 24px !important;
}

.btn2 {
  width: 200px !important;
  margin: 5px;
}

.disabledDev {
  pointer-events: none !important;
  opacity: 0.6 !important;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
}

.pointer-events {
  opacity: 0.8 !important;
  pointer-events: none !important;
}

.accordion-header {
  height: 50px;
}

.card-body.accordionAboutObject {
  //min-height: 790px!important;
}

.row {
  margin-bottom: 16px;
}

.disabled-bottom-margin {
  margin-bottom: 0;
}

.progress {
  height: 0.5rem !important;
}

.close:focus {
  border: none !important;
  outline: none !important;
}

.color-aqua {
  background-color: #00b2a8 !important;
}

.color-blue {
  background-color: #02acdf;
}

.color-red {
  background-color: #ee4c4c;
}

.text-aqua,
.text-aqua:active,
.text-aqua:focus,
.text-aqua:hover {
  color: #00b2a8;
}

.btn-outline-aqua {
  border-color: #00b2a8;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #00b2a8;
}

.theme-green .bs-datepicker-head {
  background-color: #00b2a8;
  border-color: #00b2a8;
}

.theme-green .bs-datepicker-body table td span.selected {
  background-color: #00b2a8;
  border-color: #00b2a8;
}

.hex-blue-color {
  background-color: #ebedf0;
}

fieldset {
  margin: 5px;
  min-width: 0;
  padding: 10px !important;
  position: relative;
  border-radius: 4px;
  border: 1px solid #ddd;
}

legend {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  width: 35%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
  background-color: #ffffff;
}

table tbody tr td {
  min-height: 46px;
}

.table thead th,
.table thead td {
  border: none;
}

.pagination .page-item.active .page-link {
  background-color: #00b2a8;
  color: white;
  z-index: 2;
}

.width-100 {
  width: 100%;
}

.postcode-select.ng-select.ng-select-single .ng-select-container {
  height: 100%;
}

.postcode-select.ng-select.ng-select-single .ng-select-container .ng-value-container,
.postcode-select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.margin-0 {
  margin: 0;
}
.margin-left-0 {
  margin-left: 0;
}
.margin-right-0 {
  margin-right: 0;
}
.padding-0 {
  padding: 0;
}
.padding-left-0 {
  padding-left: 0;
}
.padding-right-0 {
  padding-right: 0;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #ffffff;
  opacity: 0.3;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #ffffff;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 22px;
}

.form-control:focus,
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}

.form-control {
  font-size: 14px;
}
.ng-select.ng-select-single .ng-select-container {
  height: 33px;
  border: 1px solid #d0d0d0;
}

.p-inputtext {
  border: 1px solid #d0d0d0;
}
.ng-select .ng-select-container {
  min-height: 33px;
}

.form-label-v {
  font-weight: 500;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.option-street {
  margin: 0;
}
.option-parent-street {
  font-size: 0.8rem;
  font-style: italic;
  margin: 0;
}

.badge-green {
  background-color: #4eb1a9;
}
.pagination .page-item.active .page-link:hover {
  background-color: #03928a;
}

.dropdown-item {
  outline: none;
  &.active,
  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
}
.reason-radio {
  .mat-radio-button {
    display: block;
  }
  .mat-radio-input {
    width: 18px;
    height: 16px;
  }
  .mat-radio-label-content {
    margin-bottom: 4px;
  }
  .mat-radio-outer-circle {
    border: none !important;
  }
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
  border: none;
  outline: none;
}

.nav-tabs .nav-item .nav-link {
  font-weight: 500;
  color: #656c75;
  font-size: 16px;
  outline: none;
  border: none;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  border: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  border: none;
}

.nav-tabs .nav-link.active {
  color: #00b2a8 !important;
  border-bottom: 3px solid #00b2a8 !important;
}

button.active,
button:active,
button:hover,
button:focus {
  outline: none;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.check-block {
  align-items: center;
  &.mt-24 {
    margin-top: 24px;
  }
  .form-label-v {
    margin: 0 8px 0 0;
  }
}

@media only screen and (max-width: 1024px) {
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
  .card-body {
    padding: 0.5rem;
  }
  h5.card-title {
    font-size: 14px;
  }
}

.modal-dialog {
  .modal-header {
    padding: 0.5rem;
    align-items: center;
    justify-content: inherit;
    text-align: inherit;
    .title {
      width: calc(100% - 20px);
      display: flex;
      float: left;
      justify-content: center;
      font-size: 18px;
    }
    .close {
      margin: 0;
      padding: 0;
      float: right;
      background: none;
      border: none;
    }
  }
  .message {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .center {
    text-align: center;
  }
}

.modal,
bs-datepicker-container {
  z-index: 9999 !important;
}

.create-lead-modal {
  .modal-header {
    .title {
      text-align: left;
      font-weight: bold;
      justify-content: flex-start;
      padding-left: 10px;
    }
  }

  textarea.form-control {
    height: auto !important;
    min-height: 60px !important;
  }

  .form-control.ng-select {
    border: none;
    padding: 0;
  }
}
bs-datepicker-container {
  z-index: 999999;
}

.toast-top-right {
  margin-top: 3rem;
}

.categoryModalWindow .modal-dialog {
  max-width: 470px;
}

.videoJs-dimensions {
  width: 585px;
  height: 300px;
}

.reason-radio {
  margin-bottom: 10px;
  label {
    margin-bottom: 0;
    margin-left: 5px;
  }
}
.p-selectbutton .p-button.p-highlight {
  background: #00b2a8 !important;
  border-color: #00b2a8 !important;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}

.fill-width-50 {
  .p-buttonset .p-button {
    width: 50%;
  }
}

.select-field {
  .p-buttonset .p-button {
    padding: .375rem .75rem;
    height: 33px;
    font-size: 14px;
  }

  &.readonly {
    .p-buttonset .p-button {
      background-color: #e9ecef;
      opacity: 1;
    }
  
  }
}

.white-text {
  color: #ffffff !important;
}

.p-progressbar {
  height: 6px !important;
}

.d-flex {
  display: flex;
}

.d-pi-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mb-10px {
  margin-bottom: 13px;
}

.width-100px {
  width: 110px;
}

.fa-minus-circle:before {
  font-size: 27px;
  color: red;
}

.p-calendar-timeonly {
  height: 32px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-error {
  font-size: 12px;
  margin-top: 0px;
  display: block;
  bottom: -17px;
  position: absolute;

  @media (max-width: 599px) {
    font-size: 10px;
  }
}

ng-select {
  &.border-danger {
    .ng-select-container {
      border-color: #dc3545 !important;
    }
  }
}
.mb-16 {
  margin-bottom: 16px;
}

.phone-field-mask {
  display: block;
  & .p-inputmask {
    &::placeholder {
      color: #495057;
    }
    &:focus {
      outline: none;
    }
  }
}

.form-control {
  &.bl-none {
    border-left: none;
  }
}

.blur {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  transition: opacity .15s linear;
  opacity: 0.5;
}