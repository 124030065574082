.cal-month-view .cal-header {
  text-align: center;
  font-weight: bolder;
}

.cal-month-view .cal-header .cal-cell {
  padding: 0.3125rem 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.cal-month-view .cal-days {
  border: none;
  border-bottom: 0;
}

.cal-month-view .cal-cell-top {
  /*min-height: 4.875rem!important;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;*/
  border-radius: 50%!important;
  position: relative;
  width: 100%;
}
.cal-cell-top.ng-star-inserted:after{
  display: block;
  content: "";
  padding-bottom: 100%;
}

.cal-month-view .cal-cell-top > span{
  top: calc(50% - 1rem);
  left: calc(50% - 0.6rem);
  display: inline-grid;
  position: absolute;
  text-align: center;
}

.cal-month-view .cal-cell-row {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
}

.cal-month-view .cal-cell {
  /*float: left;*/
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  /*display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;*/
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 5px;
}

.cal-month-view .cal-cell .cal-event {
  pointer-events: all !important;
}

.cal-month-view .cal-day-cell {
  /*max-width: 5rem!important;
  min-width: 5rem!important;
  min-height: 5rem!important;
  max-height: 5rem!important;*/
  position: static;
}

@media all and (-ms-high-contrast: none) {
  .cal-month-view .cal-day-cell {
    display: block;
  }
}

.cal-month-view .cal-day-badge {
  /*display: inline-block; */
  min-width: .625rem;
  padding: .1875rem .4375rem;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  /* white-space: nowrap; */
  /* vertical-align: middle; */
  border-radius: .625rem;
  /* position: relative; */
  /* top: 0.85rem; */
  /* left: 1.25rem; */
  /* float: right; */
  /* display: block; */
  order: 2;
}

.cal-month-view .cal-day-number {
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.5;
  color: #1f1f1f;
  /*margin-right: 1.75rem;
  float: right;*/
}

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer;
  /*border-radius: 50%;*/
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.1;
  cursor: default;
}

/*.cal-month-view .cal-day-cell.cal-today .cal-day-number {*/
/*  font-size: 1.9em; }*/

.cal-month-view .cal-open-day-events {
  padding: 0.9375rem;
}

.cal-month-view .cal-open-day-events .cal-event {
  position: relative;
  top: 0.125rem;
}

.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
  opacity: 0.3;
}

.cal-month-view .cal-draggable {
  cursor: move;
}

.cal-month-view .cal-drag-active * {
  pointer-events: none;
}

.cal-month-view .cal-event-title {
  cursor: pointer;
}

.cal-month-view .cal-event-title:hover {
  text-decoration: underline;
}

.cal-month-view {
  background-color: #fff;
}

.cal-month-view .cal-cell-row:hover {
  background-color: #fafafa;
}

.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #ededed;
}

.cal-month-view .cal-days {
  border-color: #e1e1e1;
}

.cal-month-view .cal-day-badge {
  background-color: #f0d173;
  color: #fff;
}

.cal-month-view .cal-day-cell:focus .cal-cell-top .cal-day-badge {
  background-color: #fff!important;
  color: #f0d173!important;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #8b0000;
}

.cal-month-view .cal-day-cell.cal-drag-over {
  background-color: #e0e0e0 !important;
}

/*00*/
.cal-month-view .cal-day-cell {
  border: none !important;
}

.cal-month-view .cal-day-cell:active, .cal-month-view .cal-day-cell:focus {
  /*width: 5rem;
  height: 5rem;
  background-color: #f0d173 !important;*/
  border-radius: 50%;
  border: none!important;
  outline: none!important;
}

.cal-month-view .cal-day-cell:hover {
  /*width: 5rem;
  height: 5rem;*/
  border-radius: 50%;
  border: none!important;
  outline: none!important;
}

.cal-month-view .cal-day-cell.cal-today {
  /*background-color: #e8fde7!important;
  max-width: 5rem!important;
  min-width: 5rem!important;
  min-height: 5rem!important;
  max-height: 5rem!important;
  border-radius: 50%;*/
}

.cal-month-view .cal-day-cell.cal-today .cal-cell-top{
  background-color: #e8fde7!important;
}

.cal-month-view .cal-day-cell:focus .cal-cell-top {
  /*width: 5rem;
  height: 5rem;*/
  background-color: #f0d173 !important;
}

.cal-month-view .cal-day-cell:hover .cal-cell-top {
  background-color: #ededed;
}

.cal-cell-row:hover {
  background: transparent!important;
}
